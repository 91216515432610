import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Text,
  Stack,
  Link,
  Skeleton,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import Avatar from 'boring-avatars'
import { signOut } from 'firebase/auth'
import { getAuth } from 'lib/firebase/web/auth'
import { useAuth } from 'lib/firebase/web/auth/provider'

interface Props {
  children: React.ReactNode
}
export type LayoutProps = Props
export function Layout({ children }: LayoutProps) {
  const auth = useAuth()
  return (
    <Box minH={'100vh'} backgroundColor="gray.100">
      <Box mb={6}>
        <Container maxWidth={'container.lg'} height={20}>
          <HStack justifyContent={'space-between'} height={'full'}>
            <Stack
              direction={['column', 'row']}
              spacing={[0, 2]}
              alignItems={['flex-start', 'center']}
            >
              <Heading
                fontSize={['xl', '2xl']}
                fontWeight={'black'}
                letterSpacing={'wider'}
              >
                <NextLink href={'/'} passHref>
                  <Link _hover={{ textDecoration: 'none' }}>
                    <Stack direction={['column', 'row']} spacing={0}>
                      <Text>ワインスクール</Text>
                      <Text>井上塾</Text>
                    </Stack>
                  </Link>
                </NextLink>
              </Heading>
              <Text fontSize={['xs', 'md']}>リモートクラス</Text>
            </Stack>
            <MyPageMenu displayName={auth.user?.displayName || '受講生'} />
          </HStack>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={'container.lg'}>{children}</Container>
      </Box>
    </Box>
  )
}

interface MyPageMenuProps {
  displayName: string
}
function MyPageMenu({ displayName }: MyPageMenuProps) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme={'blue'}
        px={2}
        py={[4, 2]}
        pr={[2, 4]}
        rounded={'full'}
        height={'auto'}
      >
        <Stack
          spacing={1}
          direction={['column', 'row']}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Avatar
            size={30}
            name="Maria Mitchell"
            variant="beam"
            colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
          />
          <Text fontSize={['2xs', 'md']}>マイページ</Text>
        </Stack>
      </MenuButton>
      <MenuList>
        <MenuGroup title={`${displayName}さん`}>
          <MenuItem
            onClick={() => {
              signOut(getAuth())
            }}
          >
            ログアウト
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}
function MyPageMenuSkelton() {
  return <Skeleton height={10} width={24} />
}
